import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {BehaviorSubject} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class SharedService {

  loader: boolean = false;
  isLanguageChanged: boolean = false;
  remainingTimeForSurvey: BehaviorSubject<string> = new BehaviorSubject<string>('');
  submitButton: boolean = true;
  successErrorMsg: any = {
    messageHeading: '',
    message: '',
    showAcceptReject: false,
    showProceed: false,
    connectionCode: '',
    nextSurveyId: '',
    firstSurveyId: '',
    lastSurveyId: '',
    isMsgFromInterceptor: false,
    isRequestSurvey: false
  };
  messageText: any = '';

  constructor(private toastr: ToastrService) { }


  // **************** Method to start Loader ******************************
  startLoader() {
    this.loader = true;
  }
  // **************** Method to stop Loader ******************************
  stopLoader() {
    this.loader = false;
  }
  // ***************** Method to send Toast Msgs *************************
  showToastMesaages(type: any, msg: any) {
    this.toastr.clear();
    if (type == 'success') {
      this.toastr.success(msg);
    } else if (type == 'error') {
      this.toastr.error(msg);
    } else if (type == 'info') {
      this.toastr.info(msg);
    }
  }
}
