// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
// RxJS
import { Auth } from 'aws-amplify';
import { from, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';


/**
 * This will append jwt token for the http requests.
 *
 * @export
 * @class JwtInterceptor
 * @implements {HttpInterceptor}
 */

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        //get the idToken
        const jwtToken = localStorage.getItem('idToken');

        if (jwtToken) {
            const jwtDecodedToken = this.getDecodedAccessToken(jwtToken);
            const tokenExpirationTime = jwtDecodedToken.exp;
            const currentTime = Math.floor(new Date().getTime() / 1000);

            if (currentTime < tokenExpirationTime) {
                // if the token is not expired
                let with_auth_request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${jwtToken}`
                    }
                });
                return next.handle(with_auth_request);
            }
        }


        return from(Auth.currentSession()).pipe(
            switchMap((auth: any) => { // switchMap() is used instead of map().            
                let jwt = auth.getIdToken().jwtToken;
                localStorage.setItem('idToken', jwt);

                let with_auth_request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${jwt}`
                    }
                });
                return next.handle(with_auth_request);
            }),
            catchError((err) => {
                console.log("Error ", err);
                return next.handle(request);
            })
        );
    }

    getDecodedAccessToken(token: string): any {
        try {
            return jwt_decode(token);
        } catch (Error) {
            return null;
        }
    }
}