import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () => import('../app/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'home',
    loadChildren: () => import('../app/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'claims',
    loadChildren: () => import('../app/claims/claims.module').then(m => m.ClaimsModule),
  },
  {
    path: 'help',
    loadChildren: () => import('../app/help/help.module').then(m => m.HelpModule),
  },
  {
    path: 'my-surveys',
    loadChildren: () => import('../app/my-surveys/my-surveys.module').then(m => m.MySurveysModule),
  },
  { path: '**', redirectTo: 'auth', pathMatch: 'full' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
