import { Component } from '@angular/core';
import { SharedService } from './shared/shared.service';
//import { OAuthService } from 'angular-oauth2-oidc';
// import { authConfig } from './configs/auth.config';
//import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  serviceLoader: boolean = false;

  constructor(private sharedService: SharedService) {
   // this.configureOauth();
  }

  // private configureOauth() {
  //   this.oauthService.configure(authConfig);
  //   this.oauthService.tokenValidationHandler = new JwksValidationHandler();
  //   this.oauthService.loadDiscoveryDocumentAndTryLogin();
  //   /*https://manfredsteyer.github.io/angular-oauth2-oidc/docs/additional-documentation/refreshing-a-token.html */
  //   this.oauthService.setupAutomaticSilentRefresh();
  // }

  ngAfterContentChecked() {
    this.serviceLoader = this.sharedService.loader;
  }
}
